import {HStack, Skeleton, Stack} from '@chakra-ui/react';
import SkeletonBox from './components/SkeletonBox';

function AppRoutesSkeletonLoader() {

  return (
      <Stack
          spacing={6}
          width={'100%'}
          px={2}
          py={2}
      >

        <HStack
            spacing={4}
            overflowX={'hidden'}
        >

          {[...Array(10)].map((_, i) =>
              <Skeleton
                  key={i}
                  minWidth={'100px'}
                  height={'30px'}
              />
          )}
        </HStack>



        <Stack
            spacing={4}
            justify={'center'}
            align={'center'}
            minH={'25vh'}
        >

            <Skeleton
                minWidth={'300px'}
                height={'50px'}
            />
            <Skeleton
                minWidth={'500px'}
                height={'50px'}
            />
            <Skeleton
                minWidth={'100px'}
                height={'30px'}
            />
        </Stack>


        {[...Array(3)].map((_, i) =>
        <Stack key={i}>
          <Skeleton
              fadeDuration={3}
              width={'100px'}
              height={'30px'}
          />
          <HStack
              fadeDuration={3}
              spacing={4}
              overflowX={'hidden'}
          >


            {[...Array(5)].map((_, j) =>
                <Skeleton
                    fadeDuration={3}
                    key={j}
                    minWidth={'300px'}
                    height={'150px'}
                />
            )}
          </HStack>
        </Stack>
        )}

      </Stack>
  )
}

export default AppRoutesSkeletonLoader;